<template>
  <div class="container" id="Login">
    <div v-if="carregando == true" class="carregando">
      <!--img width="100" src="https://bboneapp.s3.amazonaws.com/evo-white.png" /-->
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <b-row class="mt-5 text-center" v-if="erroConexao != ''">
      <b-col>
        <img :src="logo" style="max-width: 90%" />
        <div class="alert alert-warning">{{ erroConexao }}</div>
        <b-button @click="buscaDadosEmpresa()">Tentar Novamente</b-button>
      </b-col>
    </b-row>
    <b-row v-else class="mt-5">
      <b-col class="text-center">
        <img :src="logo" />
        <div class="mt-5 alert alert-warning">
          Identificamos que você ainda não trocou sua senha, altere agora para
          sua segurança
        </div>

        <b-form @submit.stop.prevent="onSubmit" class="mt-4 text-left">
          <div>
            <b-form-group id="input-group-2" label label-for="input-2">
              <password
                v-model="form.senha"
                id="Senha"
                name="Senha"
                placeholder="Nova Senha"
                :secureLength="5"
                :badge="false"
                v-validate="{ required: true }"
                :state="validateState('Senha')"
                :toggle="true"
                @score="showScore"
              />
            </b-form-group>
          </div>
          <b-button pill type="submit" class="btn-block mt-3" variant="dark"
            >Alterar</b-button
          >
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>
<!-- eslint-disable -->
<script>
import locale from './../locale';
import service from '../services';
import jwt from 'jsonwebtoken';
import Password from 'vue-password-strength-meter';

export default {
  name: 'TrocaSenha',
  props: {
    msg: String,
  },
  data() {
    return {
      form: {
        senha: null,
      },
      score: 0,
      erroConexao: '',
      carregando: false,
      logo: 'https://bboneapp.s3.amazonaws.com/evo-gerencial-color.png',

      value: 80,
      max: 100,
    };
  },
  components: { Password },
  methods: {
    showScore(score) {
      this.score = score;
    },
    selecionaTab(valor) {
      this.form.formSelecionado = valor;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit(evt) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }

        if (this.score < 2) {
          this.$bvToast.toast(
            'Sua Senha Não atingiu os requisitos minimos de Segurança, tente novamente.',
            {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            },
          );
          return;
        }
        const token = localStorage.getItem('user-token');
        this.form.token = token;
        service
          .post('Usuario', 'TrocarSenha', this.form)
          .then((res) => {
            this.carregando = false;

            localStorage.setItem('user-token', res.data.token);
            this.$router.push('/dashboard');
          })
          .catch((e) => {
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    buscaDadosEmpresa() {
      return new Promise((resolve, reject) => {
        this.carregando = true;
        this.logo = localStorage.getItem('logo');
        const token = localStorage.getItem('user-token');
        if (token) {
          var decoded = jwt.decode(token);
        }
        this.form.idEmpresa = decoded.idEmpresa;
        this.carregando = false;
        console.log(this.form.idEmpresa);
      });
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.buscaDadosEmpresa();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.logoRodape {
  text-align: center;
  margin-top: 15px;
  font-size: 10px;
  display: block;
}
</style>
